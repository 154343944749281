import Image, {type ImageProps} from '@/components/base/elements/Image/Image';
import {
  containerStyles,
  imageWrapperStyles,
} from '@/components/base/modules/LogoGroup/styles';
import {twMerge} from '@/stylesheets/twMerge';
import {useTranslations} from '@/hooks/useTranslations';

export interface LogoGroupProps {
  className?: string;
  logos: ImageProps[];
  mode?: 'light' | 'dark';
}

export default function LogoGroup({
  className,
  logos = [],
  mode = 'light',
}: LogoGroupProps) {
  const length = logos.length;
  const componentName = 'logo-group';
  let numberOfLogos: 3 | 4 | 5 | 6 | 7 | 8 | 10 | null | undefined;
  switch (length) {
    case 4:
      numberOfLogos = 4;
      break;
    case 5:
      numberOfLogos = 5;
      break;
    case 6:
      numberOfLogos = 6;
      break;
    case 7:
      numberOfLogos = 7;
      break;
    case 8:
      numberOfLogos = 8;
      break;
    case 10:
      numberOfLogos = 10;
      break;
    default:
      numberOfLogos = 3;
      break;
  }

  const {t} = useTranslations();

  return (
    <ul
      className={twMerge(containerStyles({mode, numberOfLogos}), className)}
      data-mode={mode}
      data-component-name={componentName}
      aria-label={t('global:ariaLabels.logoGroup')}
    >
      {logos.map((logo, index: any) => (
        <li
          key={`${logo.alt}-${index}`}
          className={imageWrapperStyles({index, numberOfLogos})}
        >
          <div className="max-w-[210px]">
            <Image className="w-full h-full object-cover" {...logo} />
          </div>
        </li>
      ))}
    </ul>
  );
}

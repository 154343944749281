import {cva} from 'class-variance-authority';

export const containerStyles = cva(
  'container grid justify-center items-center gap-x-md gap-y-xl py-lg border-y',
  {
    variants: {
      mode: {
        light: 'border-black/20',
        dark: 'border-white/30',
      },
      numberOfLogos: {
        3: 'grid-cols-[repeat(4,auto)] sm:grid-cols-[repeat(3,auto)]',
        4: 'grid-cols-[repeat(2,auto)] sm:grid-cols-[repeat(4,auto)]',
        5: 'grid-cols-[repeat(4,auto)] sm:grid-cols-[repeat(6,auto)] lg:grid-cols-[repeat(5,auto)]',
        6: 'grid-cols-[repeat(2,auto)] sm:grid-cols-[repeat(3,auto)] lg:grid-cols-[repeat(6,auto)]',
        7: 'grid-cols-[repeat(4,auto)] sm:grid-cols-[repeat(8,auto)]',
        8: 'grid-cols-[repeat(2,auto)] sm:grid-cols-[repeat(4,auto)]',
        10: 'grid-cols-[repeat(2,auto)] sm:grid-cols-[repeat(4,auto)] lg:grid-cols-[repeat(5,auto)]',
      },
    },
    defaultVariants: {
      numberOfLogos: 3,
    },
  },
);
export const imageWrapperStyles = cva('items-center w-full', {
  variants: {
    numberOfLogos: {
      3: 'col-span-2 sm:col-span-1',
      4: '',
      5: 'col-span-2 lg:col-span-1',
      6: '',
      7: 'col-span-2',
      8: '',
      10: '',
    },
    index: {
      2: '',
      3: '',
      4: '',
      5: '',
      6: '',
      8: '',
    },
  },
  compoundVariants: [
    {
      numberOfLogos: 3,
      index: 2,
      className: 'col-start-2 sm:col-start-auto sm:col-span-1',
    },
    {
      numberOfLogos: 5,
      index: 3,
      className: 'sm:col-start-2 lg:col-start-auto',
    },
    {
      numberOfLogos: 5,
      index: 4,
      className: 'col-start-2 sm:col-start-4 lg:col-start-auto',
    },
    {
      numberOfLogos: 7,
      index: 4,
      className: 'sm:col-start-2',
    },
    {
      numberOfLogos: 7,
      index: 5,
      className: 'sm:col-start-4',
    },
    {
      numberOfLogos: 7,
      index: 6,
      className: 'col-start-2 sm:col-start-6',
    },
    {
      numberOfLogos: 10,
      index: 8,
      className: 'sm:col-start-2 lg:col-start-auto',
    },
  ],
  defaultVariants: {
    numberOfLogos: 3,
  },
});
